'use client';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LoaderSpinner() {
	const { t } = useTranslation();
	return (
		<div className="fixed z-50 inset-0 bg-gray-1 opacity-30 w-full h-full rounded-lg">
			<div className="flex items-center justify-center h-full flex-col gap-2 cursor-progress">
				<div className="animate-spin rounded-full h-24 w-24 border-t-2 border-b-2 border-primary" />
				<p className="text-primary animate-pulse select-none font-semibold text-sm">{t('common:loading')}</p>
			</div>
		</div>
	);
}
